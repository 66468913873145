<template>
  <div>
      <van-cell/>
  </div>
</template>

<script>
import appconst from '../../lib/appconst';
import store from "../../store";
import { Toast } from "vant";

export default {
    data() {
        return {

        };
    },
    methods: {
        gotoCenter() {
            window.location.href = `${
                appconst.thirdApiCenterBaseUrl
            }Api/WxMpOauth/Wechat?appid=${appconst.wechatAppId}&next=${encodeURIComponent(
                `${window.location.href}`
            )}`;
        },
        async wxAuthorize() {
            const { accesskey, redirect } = this.$route.query;
            
            if (typeof accesskey === "string") {
                const loading = Toast.loading({
                    duration: 0,
                    forbidClick: true,
                    mask: false,
                    message: "微信授权"
                });
                // 获取微信授权信息
                try {
                    await store.dispatch("wechat/oauth", accesskey);
                } catch (ex) {
                    Toast.fail({ message: ex.message, duration: 0 });
                } finally {
                    loading.clear();
                }

                // 转回之前的页面
                if (typeof redirect === "string") {
                    this.$router.replace(redirect);
                }
                
            } else {
                this.gotoCenter();
            }
        }
    },
    async created() {
        await this.wxAuthorize();
    }
}
</script>

<style>

</style>